
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Resetpassword from './components/LogIn/Resetpassword';
import Signin from './components/LogIn/Signin';
import Signup from './components/LogIn/Signup';
import OrderManagement from './components/Pages/OrderManagement';
import RateManagement from './components/Pages/RateManagement';
import Reports from './components/Pages/Reports';
import ShippingCharge from './components/Pages/ShippingCharge';
import TimeSchedule from './components/Pages/TimeSchedule';
import UserManagement from './components/Pages/UserManagement';
import VehicleManagement from './components/Pages/VehicleManagement';
import Sidebar from './Sidebar';
import DriverManagement from './components/Pages/DriverManagement';
import Driver from './components/Pages/Driver';
import AuthService from "../src/lib/Auth"

function App() {
  AuthService.refreshToken().then((response) => { });
  setInterval(() => {
  AuthService.refreshToken().then((response) => { });
  }, 360000)

  return (
    <div className="App">
      {window.location.pathname === "/login" ? <Router>
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset" element={<Resetpassword />} />
        </Routes>
      </Router>
        : <Router >
          <Header />
          <div style={{ display: 'flex' }}>
            <Sidebar />
            <Routes>
              <Route path="/" element={<OrderManagement />}/>
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/driver-management" element={<DriverManagement />} />
              <Route path="/vehicle-management" element={<VehicleManagement />} />
              <Route path="/rate-management" element={<RateManagement />} />
              <Route path="/time-schedule" element={<TimeSchedule />} />
              <Route path="/shipping-charge" element={<ShippingCharge />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/driver-registration" element={<Driver />} />
            </Routes>
          </div>
        </Router>}
    </div>
  );
}
export default App;

