import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

const useStyles = makeStyles((theme) => ({
  paperStyling: {
    width: "91vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff"
  },
}))
function Reports() {
  const classes = useStyles()
  const orderData = {
    chart: {
      type:"column",
    },
    title:{
      text:"Order Report"
    },
    legend: {
        enabled: false
    },
     xAxis: {
        categories: ['Success Order', 'Pending Order', 'Cancellation Order', 'Accepted Order']
    },
    series:[
      {
        name: "Order",
        colorByPoint: true,
         data: [
                {
                    name: "Success Order",
                    y: 62.74,
                    color:"#005b96"
                },
                {
                    name: "Pending Order",
                    y: 10.57,
                    color:"#005b96"
                },
                {
                    name: "Cancellation Order",
                    y: 7.23,
                    color:"#005b96"
                },
                {
                    name: "Accepted Order",
                    y: 5.58,
                    color:"#005b96"
                },
            ]
      }
    ]
    }
  const revenueData={
     chart: {
      type:"column",
    },
    title:{
      text:"Revenue Report"
    },
    legend: {
        enabled: false
    },
    xAxis: {
      categories: ["Pending Amount","Completed Amount","Cancelled Amount"]
    },
     series:[
      {
        name: "Revenue",
        colorByPoint: true,
         data: [
                {
                    name: "Pending Amount",
                    y: 62.74,
                    color:"#005b96"
                },
                {
                    name: "Completed Amount",
                    y: 10.57,
                    color:"#005b96"
                },
                {
                    name: "Cancelled Amount",
                    y: 7.23,
                    color:"#005b96"
                },
            ]
      }
    ]
  }
  return (
    <div className="bar_style">
      <Paper className={classes.paperStyling}>
        <div className="bar_chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={orderData}
          />
        </div>
      </Paper>
      <Paper className={classes.paperStyling}>
        <div className="bar_chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={revenueData}
          />
        </div>
      </Paper>
    </div>    
  )
}
export default Reports

