import { alpha, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputBase, InputLabel, makeStyles, MenuItem, Paper, Select, Snackbar, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect,  useState } from 'react';
import { IconContext } from 'react-icons';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf, FaSearch, FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdAdd } from "react-icons/md";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import axios from 'axios';
import AuthService from "../../lib/Auth";
import Pagination from '@mui/material/Pagination';
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  btnAdd: {
    color:"#fff",
   backgroundColor: alpha("#005B96", 1),
  '&:hover': {
    backgroundColor: alpha("#005B96", 0.8),
    },
  },
  btnSave:{
    color: "#fff",
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
    '&:disabled': {
        backgroundColor: alpha("#005B96",0.3),
        color: "#fff",
    },
  },
  btnEdit:{
    color: "#fff",
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
  },
  dialogTitle: {
    background: "#005b96",
    width: "100%"

  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    width: "25vw",
    [theme.breakpoints.down('sm')]:{
      width:"60vw",
      gap:"1rem"
    },
  },
  dialogButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    paddingBottom: "1rem"
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  },
  btnError:{
    backgroundColor: alpha("#eb3a2d", 1),
    color:"#fff",
    '&:hover' :{
      backgroundColor: alpha("#eb3a2d", 0.90),
    }
  }
}))
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function VehicleManagement() {
  const classes = useStyles()
  const [search, setSearch] = useState("")
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [vehicleName,setVehicleName]= useState("")
  const [vehicleBrand,setVehicleBrand] = useState("")
  const [vehicleNumber,setVehicleNumber] = useState("")
  const [vehicleType,setVehicleType] = useState("")
  const [vehicleAvailability,setVehicleAvailability] = useState("")
  const [searchText,setSearchText]= useState("")
  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [openSnack,setOpenSnack] = useState(false)
  const [editSnack,setEditSnack] = useState(false)
  const [deleteSnack,setDeleteSnack] = useState(false)
  const [loader,setLoader]= useState(true)
  const [editData,setEditData]= useState({})
  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };
  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/vehicles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);
  const handleSearch = async() => {
    setSearchText(search)
  }
  const handleChangeSearchValue=async(e) => {
    await setSearch(e.target.value)
    if(e.target.value==="") {
       await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/vehicles/?limit=${pageSize}&offset=${offset}&search=${e.target.value}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
    }
  }
  const handleSubmit =async (e) => {
    e.preventDefault()
    try {
      var payload={
        vehicle_availabilty:vehicleAvailability,
        vehicle_brand: vehicleBrand,
        vehicle_name: vehicleName,
        vehicle_number:vehicleNumber,
        vehicle_type:vehicleType
      }
      await axios.post(process.env.REACT_APP_SERVER_PATH + "/api/v1/vehicles/?limit=100",
        payload, AuthService.getAuthHeader()).then((res) => { }).catch(async () => {
          await AuthService.refreshToken();
        })
      setOpenSnack(true)
      setOpenAdd(false)
      setVehicleName("")
      setVehicleBrand("")
      setVehicleNumber("")
      setVehicleType("")
      setVehicleAvailability("")
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/vehicles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
    } catch (e) {
      console.log("error in add tasting page", e);
    }
  }
  const columnData = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Vehicle Name",
      field: "vehicle_name",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Vehicle Brand",
      field: "vehicle_brand",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Vehicle No",
      field: "vehicle_number",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Vehicle Type",
      field: "vehicle_type",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Availability",
      field: "vehicle_availabilty",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Action",
      field: "action",
      headerClass: "column-style",
      width:200,
      cellRenderer: function (params) {
        return (<div><IconButton onClick={() => setOpenEdit(true)}><IconContext.Provider value={{ className: "edit_icon" }}><FaEdit size={25} /></IconContext.Provider> </IconButton>
          <IconButton onClick={() => setOpenDelete(true)}><IconContext.Provider value={{ className: "delete_icon" }}><RiDeleteBin6Fill size={25} /></IconContext.Provider> </IconButton></div>)
      }
    },
  ]
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#e6e6e6' };
    }
  };
  const gridOptions={
    defaultColDef:{
      flex:"1",
      minWidth:100,
      cellStyle:{
        fontSize:"16px",
        paddingRight:"1rem"
      }
    },
    onRowClicked: event => selectedRowData(event)
  }
  const selectedRowData = (event) => {
    setEditData(event?.data)  
    setLoader(false)
  }
  const {id,vehicle_name,vehicle_brand,vehicle_number,vehicle_type,vehicle_availabilty}=editData
  const onInputChange=(e) => {
    setEditData({...editData,[e.target.name]:e.target.value})
  }
  const handleEdit =async (e) => {
    e.preventDefault();
    await axios.put(`${process.env.REACT_APP_SERVER_PATH}/api/v1/vehicles/${id}/`,editData,
      AuthService.getAuthHeader()).catch(async () => {
      await AuthService.refreshToken();
    })
    setEditSnack(true)
    setOpenEdit(false)
    await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/vehicles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
  }
  const handleDelete = async () => {
    await axios.delete(`${process.env.REACT_APP_SERVER_PATH}/api/v1/vehicles/${id}/`,
    AuthService.getAuthHeader()).catch(async () => {
      await AuthService.refreshToken();
    })
    setDeleteSnack(true)
    setOpenDelete(false)
    await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/vehicles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
  }
  return (
    <Paper className={classes.paperStyling} elevation={2}>
      <div className="export-search-field">
        <div className="export-field">
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <SiMicrosoftexcel />
              </span>
            </IconContext.Provider>
            Export to Excel
          </button>
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <FaFilePdf />
              </span>
            </IconContext.Provider>
            Export to PDF
          </button>
          <Tooltip title=" Add items " placement="top-start">
            <Button className={classes.btnAdd} onClick={() => setOpenAdd(true)}>
              <MdAdd color="#fff" size={25} />
            </Button>
          </Tooltip>
        </div>
        <div className={classes.search}>
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67" size={20} />
          </IconButton>
          <InputBase
            placeholder="Search…"
            value={search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => handleChangeSearchValue(e)}
          />
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination
          className={classes.paginationStyle}
          count={numberOfPages}
          showFirstButton
          showLastButton
          color="primary"
          shape="rounded"
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <span className="edit_title">Add</span>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}
            >
              <TextField
                label="Vehicle Name*:"
                name="vehicle_name"
                multiline
                fullWidth
                value={vehicleName}
                onChange={(e) => setVehicleName(e.target.value)}
                // onChange={(e) => onInputChange(e)}
                inputProps={{ maxLength: 12 }}
              />
              <TextField
                label="Vehicle Brand*:"
                name="vehicle_brand"
                multiline
                fullWidth
                value={vehicleBrand}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => setVehicleBrand(e.target.value)}
              />
              <TextField
                label="Vehicle No*:"
                name="vehicle_number"
                id="outlined-multiline-static"
                multiline
                fullWidth
                value={vehicleNumber}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => setVehicleNumber(e.target.value)}
              />
              <TextField
                name="vehicle_type"
                label="Vehicle Type*:"
                id="outlined-multiline-static"
                multiline
                fullWidth
                value={vehicleType}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => setVehicleType(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Availability*:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Please Select"
                  name="vehicle_availability"
                  fullWidth
                  value={vehicleAvailability}
                  onChange={(e) => setVehicleAvailability(e.target.value)}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="Please Select" disabled>
                    Please Select
                  </MenuItem>
                  <MenuItem key="1" value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="2" value="No">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button
              className={classes.btnSave}
              variant="contained"
              type="submit"
              disabled={!vehicleName||!vehicleBrand||!vehicleType||!vehicleNumber||!vehicleAvailability}
            >
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpenAdd(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <span className="edit_title">Edit</span>
        </DialogTitle>
        <form onSubmit={handleEdit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}
            >
              {loader ? <div className="loader"><CircularProgress /></div> : <><TextField
                label="ID:"
                name="id"
                value={id}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => onInputChange(e)}
              />
                <TextField
                  label="Vehicle Name:"
                  name="vehicle_name"
                  value={vehicle_name}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onInputChange(e)}
                />
                <TextField
                  label="Vehicle Brand:"
                  name="vehicle_brand"
                  value={vehicle_brand}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onInputChange(e)}
                />
                <TextField
                  label="Vehicle No:"
                  name="vehicle_number"
                  id="outlined-multiline-static"
                  fullWidth
                  value={vehicle_number}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onInputChange(e)}
                />
                <TextField
                  name="vehicle_type"
                  label="Vehicle Type:"
                  id="outlined-multiline-static"
                  fullWidth
                  value={vehicle_type}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onInputChange(e)}
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Availability:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    label="Please Select"
                    name="vehicle_availabilty"
                    fullWidth
                    value={vehicle_availabilty}
                    onChange={(e) => onInputChange(e)}
                    MenuProps={MenuProps}
                  >
                    <MenuItem key="1" value="Yes">
                      Yes
                    </MenuItem>
                    <MenuItem key="2" value="No">
                      No
                    </MenuItem>
                  </Select>
                </FormControl></>}              
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button
              className={classes.btnEdit}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpenEdit(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete ?
        </DialogTitle>
        <DialogActions>
          <Button
            className={classes.btnError}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={() => setOpenDelete(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          Added New Item
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={editSnack}
        autoHideDuration={2000}
        onClose={() => setEditSnack(false)}
      >
        <Alert onClose={() => setEditSnack(false)} severity="success">
          Edit Saved
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={deleteSnack}
        autoHideDuration={2000}
        onClose={() => setDeleteSnack(false)}
      >
        <Alert onClose={() => setDeleteSnack(false)} severity="error">
          Item Deleted
        </Alert>
      </Snackbar>
    </Paper>
  );
}
export default VehicleManagement

