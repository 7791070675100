import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import logo from "../../images/Arti_Petroleum_logo.png"
import Snackbar from '@material-ui/core/Snackbar'
import AuthService from "../../lib/Auth"
import MuiAlert from "@material-ui/lab/Alert"
const Signin = () => {

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const Alert = React.forwardRef(function Alert(props, ref) {

        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    
      });
  const submitForm = (e)=>{
    e.preventDefault();

    let credentials = {
        username: email.toLowerCase(),
        password: password,
      };
      AuthService.login(credentials)
        .then((res) => {
          if (res.data && res.data.access) {
            localStorage.setItem("useremail", email);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            window.location.href = "/";
          } else {
            console.log("Data: ", res.data);
          }
        })
        .catch((error) => {
        console.log("error", error)
          setOpen(true);
        });
  }

  
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <><div className="auth-wrapper">
          <div className="auth-content">
              <div className="auth-bg">
                  <span className="r"></span>
                  <span className="r s"></span>
                  <span className="r s"></span>
                  <span className="r"></span>
                  <span className="r xl"></span>
                  <span className="r xl"></span>
                  <span className="r l"></span>
                  <span className="r l"></span>
              </div>
              <div className="card">
                  <div className="card-body text-center">
                      <div className="mb-4 logo">
                          <img src={logo} alt="logo" />

                      </div>
                      <h3 className="mb-4">Login</h3>
                      <form onSubmit={submitForm} >
                          <div className="input-group mb-3">
                              <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                          </div>
                          <div className="input-group mb-4">
                              <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                          </div>
                          {/* <div className="form-group text-left">
        <div className="checkbox checkbox-fill">
            <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" />
            <label for="checkbox-fill-a1" className="cr"> Save Details</label>
        </div>
    </div> */}
                          <button className="btn btn-primary shadow-2 mb-4" type='submit'>Login</button>
                      </form>
                      <p className="mb-2 text-muted">Forgot password?<NavLink to="/reset">Reset</NavLink></p>
                      <p className="mb-0 text-muted">Don’t have an account?<NavLink to="/signup">Sign up</NavLink></p>
                  </div>
              </div>
          </div>
      </div>

      <Snackbar
          anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
          }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
      >
              <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                  Username or password is not valid.
              </Alert>
          </Snackbar>
          </>
  )
}
export default Signin

