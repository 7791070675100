import { alpha, InputBase, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaSearch, FaFilePdf } from "react-icons/fa";
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from 'axios';
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";


import AuthService from '../../lib/Auth';

import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  btnError: {
    backgroundColor: "#EC3C2F",
    color: "#fff"
  },
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
     [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  search: {
    position: 'relative',
    display:"flex",
    alignItems:"center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}))


function UserManagement() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchText,setSearchText]= useState("");
  const [search, setSearch] = useState("");
  const [selectedRowId, setSelectedRowId]= useState("");
  const rowStyle = { background: '#fff' };

  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);

  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };

  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/profiles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);
  
  const handleSearch = () => {
    setSearchText(search)
  }
  const handleChangeSearchValue=async(e) => {
    await setSearch(e.target.value)
    if(e.target.value==="") {
       await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/profiles/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
    }
  }
  
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#e6e6e6' };
    }
  };
  const handleDelete = () => {

  }
  const columnData = [
    
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Action",
      field: "action",
      headerClass: "column-style",
      cellRenderer: function (params) {
        return <IconButton onClick={() => setOpen(true)}><IconContext.Provider value={{ className: "delete_icon" }}><RiDeleteBin6Fill size={25} /></IconContext.Provider> </IconButton>
      }
    }
  ]
  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth:100,
      overflowX: 'scroll',
      resizable: true,
      cellStyle: {
        fontSize: "16px",
      }
    },
    onRowClicked: event => selectedRowData(event)
  }
  const selectedRowData = (event) => {
    setSelectedRowId(event.data.id)
  }
  
  return (
    <Paper className={classes.paperStyling} elevation={2}>
      <div className="export-search-field">
        <div className="export-field">
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <SiMicrosoftexcel />
              </span>
            </IconContext.Provider>
            Export to Excel
          </button>
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <FaFilePdf />
              </span>
            </IconContext.Provider>
            Export to PDF
          </button>
        </div>
        <div className={classes.search}>
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67" size={20}  />
           </IconButton>
          <InputBase
            placeholder="Search…"
            value={search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="ag-theme-alpine" >
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          shape="rounded"
          color="primary" 
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete ?</DialogTitle>
        <DialogActions>
          <Button
            className={classes.btnError}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={() => setOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
export default UserManagement     

