import { AgGridReact } from 'ag-grid-react'
import React, {  useEffect, useState} from 'react';
import {FaFilePdf,FaSearch} from "react-icons/fa"
import {SiMicrosoftexcel} from "react-icons/si"
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css"
import {alpha, Button,  Dialog,  DialogActions,  DialogContent,  DialogContentText,  DialogTitle,  FormControl,  IconButton,  InputBase, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {MdAdd} from "react-icons/md"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import axios from 'axios';
import AuthService from "../../lib/Auth"
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff"
  },
  btnAdd: {
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
    color: "#fff",
  },
  btnError: {
    color: "#fff",
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
    '&:disabled': {
      backgroundColor: alpha("#005B96",0.3),
      color: "#fff",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  dialogTitle :{
    background: "#005b96",
    width: "100%"
  },
  dialogContent :{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    width:"25vw",
    [theme.breakpoints.down('sm')]:{
      width:"60vw",
      gap:"1rem"
    },
  },
  dialogButtons :{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down('sm')]:{
      gap:"0.8rem"
    },
  },
  search: {
    position: 'relative',
    display:"flex",
    alignItems:"center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RateManagement() {
  const [date,setDate]= useState(new Date())
  const [rate , setRate] = useState("");
  const [title, setTitle] = useState("");
  const classes =useStyles();
  const[open , setOpen] = useState(false);
  const[search, setSearch] = useState("");
  const[searchText ,setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [openSnack,setOpenSnack] = useState(false)
  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };
   useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/rates/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ])

  const columnData = [
    {
      headerName: "ID",
      field: "id",
      sortable:true,
      headerClass:"column-style"
    },{
      headerName: "Date",
      field:"date_of_new_rate",
      sortable:true,
      headerClass:"column-style",
      cellRenderer:function(params) {
        return (moment(params.value).format("YYYY-MM-DD"))
      }
    },
    {
      headerName: "Title",
      field: "title",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Rate",
      field: "rate",
      sortable:true,
      headerClass:"column-style"
    },
  ]


 
  const handleDateChange = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    setDate(year + "-" + month + "-" + day);
  }
  const gridOptions={
    defaultColDef:{
      flex:1,
      minWidth:100,
        cellStyle:{
        fontSize:"16px",
        }
    },
  }
 
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
          return { background: '#e6e6e6' };
      }
  };
  const handleSubmit = async(e) => {
    e.preventDefault()
    try{
      var payload = {
          date_of_new_rate: date,
          rate: rate,
          title: title,
      }
      await axios.post(process.env.REACT_APP_SERVER_PATH + "/api/v1/rates/", payload, AuthService.getAuthHeader())
      .then((res)=>{
      }).catch(async() => {
        await AuthService.refreshToken()
      })
      setOpenSnack(true) 
      setOpen(false)
      setDate(new Date())
      setRate("")
      setTitle("")
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/rates/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })

    }
    catch (e) {
      console.log("error inrate management page", e);
    }
  }
  const handleChangeSearchValue=async(e) => {
    setSearch(e.target.value)
    if(e.target.value==="") {
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/rates/?limit=${pageSize}&offset=${offset}&search=${e.target.value}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })  
    }
  }
  const handleRate=(e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setRate(e.target.value);
    }
  }
  const handleSearch = ()=>{
    setSearchText(search)
  }

  return (
    <Paper className={classes.paperStyling} elevation={2} >    
     <div className='export-search-field'>
        <div className='export-field'>
            <button type="button"><span><SiMicrosoftexcel /></span> Export to Excel</button>
            <button type="button"><span><FaFilePdf /></span> Export to PDF</button>
            <Tooltip title=" Add items " placement="top-start">
                <Button className={classes.btnAdd} onClick={() => setOpen(true)}>
                  <MdAdd color='#fff' size={25} />
                </Button>
            </Tooltip>
            
        </div>
        <div className={classes.search}>
      
      <IconButton onClick={handleSearch}>
        <FaSearch color="#3F4D67"  size={20} />
        </IconButton>

      <InputBase
        placeholder="Search…"
        value={search}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => handleChangeSearchValue(e)}
      />
    </div>
    </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          shape='rounded'
          color='primary'  
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          <span className='edit_title'>
           Add
          </span>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}             
            >
             
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant='inline'
                  label="Date"
                  name="date_of_new_rate"
                  value={date}
                  onChange={handleDateChange}
                  format="yyyy-MM-dd"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
              <TextField
                name="rate"
                id="outlined-multiline-static"
                label="Rate*:"
                multiline
                fullWidth               
                value={rate}
                inputProps={{maxLength: 1}}
                onChange={(e)=>handleRate(e)}
              />
              <FormControl fullWidth>
                <InputLabel>
                 Fuel Title
                </InputLabel>
                <Select
                  label="Fuel Title"
                  name="title"
                  fullWidth
                  onChange={(e)=>setTitle(e.target.value)}
                  value={title}
                >
                  <MenuItem value="" disabled selected>Please Select</MenuItem>
                  <MenuItem value="Petrol">Petrol</MenuItem>
                  <MenuItem value="Diesel">Diesel</MenuItem>
                </Select>
              </FormControl> 
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button className={classes.btnError} variant="contained" type="submit" disabled={!rate||!title}>
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          Added New Item
        </Alert>
      </Snackbar>
    </Paper>
  );
}
export default RateManagement;

