
import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react';
import {FaFilePdf,FaSearch} from "react-icons/fa";
import {RiDeleteBin6Fill} from "react-icons/ri";
import {SiMicrosoftexcel} from "react-icons/si";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Button, Dialog, DialogActions, DialogTitle, IconButton, InputBase, Snackbar} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { IconContext } from 'react-icons';
import axios from 'axios';
import AuthService from '../../lib/Auth';
import Pagination from '@mui/material/Pagination';
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnAdd: {
    background: "#eb3a2d",
    color: "#fff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  btnError: {
    backgroundColor: alpha("#eb3a2d", 1),
    color:"#fff",
    '&:hover' :{
      backgroundColor: alpha("#eb3a2d", 0.90),
    }
  },
  search: {
    position: 'relative',
    display:"flex",
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
     paperStyling:{
    width: "100vw",
    height: "calc(100vh -4rem)", 
    marginInline:"2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
     [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function DriverManagement() {
  const classes =useStyles()
  const [open,setOpen]=useState(false)
  const [searchText,setSearchText]= useState("")
  const [search, setSearch]= useState("")
  const [selectedRowId, setSelectedRowId]= useState("")

  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [deleteSnack,setDeleteSnack] = useState(false)
  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };
  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/drivers/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);
  
  const handleSearch = () => {
    setSearchText(search)
  }
  const handleChangeSearchValue=async(e) => {
    await setSearch(e.target.value)
    if(e.target.value==="") {
       await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/drivers/?limit=${pageSize}&offset=${offset}&search=${e.target.value}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
    }
  }
  const columnData = [
    {
      headerName: "Name",
      field: "name",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Address",
      field: "address",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Email",
      field: "email_id",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Phone",
      field: "phone",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "License",
      field: "license_id",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Action",
      field: "action",
      headerClass:"column-style",
      cellRenderer: function (params) {
        return <IconButton onClick={() => setOpen(true)}> <IconContext.Provider value={{className:"delete_icon"}}><RiDeleteBin6Fill size={25}/></IconContext.Provider> </IconButton>
        }
    },
  ];
  useEffect(() =>{
    data.forEach(object => {
      object.name=`${object.first_name} ${object.middle_name} ${object.last_name}`
    });
  })
  const gridOptions={
    defaultColDef:{
      flex:"1",
      minWidth:100,
        cellStyle:{
          fontSize:"16px",
        }
  },
   onRowClicked: event => selectedRowData(event)
  }
  const selectedRowData = (event) => {
    setSelectedRowId(event.data.id)
  }
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#e6e6e6' };
    }
  };
  const handleDelete=async() => {
    await axios.delete(`${process.env.REACT_APP_SERVER_PATH}/api/v1/drivers/${selectedRowId}/`,
    AuthService.getAuthHeader()).catch(async () => {
      await AuthService.refreshToken();
    })
    setDeleteSnack(true)
    setOpen(false)
    await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/drivers/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
  }
  return (
    <Paper className={classes.paperStyling} elevation={2} >
     <div className='export-search-field'>

        <div className='export-field'>
            <button type="button"><span><SiMicrosoftexcel /></span> Export to Excel</button>
            <button type="button"><span><FaFilePdf /></span> Export to PDF</button>
        </div>
        <div className={classes.search}>
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67" size={20}  />
          </IconButton>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={search}
            onChange={(e) =>handleChangeSearchValue(e)}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          shape='rounded'
          color='primary' 
          onChange={handlePagination}
        />
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete ?</DialogTitle>
        <DialogActions>
          <Button
            className={classes.btnError}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={() => setOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={deleteSnack}
        autoHideDuration={2000}
        onClose={() => setDeleteSnack(false)}
      >
        <Alert onClose={() => setDeleteSnack(false)} severity="error">
          Item Deleted
        </Alert>
      </Snackbar>
    </Paper>
  );
}
export default DriverManagement;

