
import React from "react";
// import {FaUserAlt} from "react-icons/fa"
import {BiLogOutCircle} from "react-icons/bi"
import AuthService from "../../lib/Auth"
import { Avatar, makeStyles } from "@material-ui/core";
const useStyles= makeStyles((theme) => ({
  userImg:{
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "transparent",
    cursor: "pointer",
    float: "right"
  }
}))
const Header = () => {
  const classes=useStyles()
  const email=localStorage.getItem("useremail")
  const handleLogout=async(e) => {
    e.preventDefault();
    await AuthService.logOut()
  }


  return (
    <div className="header">
      <Avatar
        className={classes.userImg}
      />
      {email}
      <div className="logout" onClick={handleLogout}>
        <BiLogOutCircle size={25} color="white" />
        Logout
      </div>
    </div>
  );
}
export default Header

