import { alpha, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputBase, InputLabel, makeStyles, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FaSearch, FaEdit, FaFilePdf } from "react-icons/fa"
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css"
import { SiMicrosoftexcel } from "react-icons/si"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { IconContext } from 'react-icons';
import axios from 'axios';
import AuthService from "../../lib/Auth";
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    backgroundColor: "#005B96",
    fontSize:"0.8rem"
  },
  btnError: {
    color: "#fff",
    backgroundColor: "#eb3a2d",
    fontSize:"0.8rem"
  },
  btnClose:{
    fontSize:"0.8rem"
  },
  btnGo:{
    marginInline:"0.8rem",
    color: "#fff",
    width:"170px",
    paddingBlock:"0.6rem",
    [theme.breakpoints.down('xs')]:{
      width:"94%",
      marginTop:"0.8rem"
    },
    backgroundColor: alpha("#005B96", 1),
  '&:hover': {
    backgroundColor: alpha("#005B96", 0.8),
  },
  },
  dialogTitle: {
    background: "#005b96",
    width: "100%"
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
  },
  dialogButtons: {
    marginInline: "1rem",
    marginBottom: "1rem",
    display:"flex",
    alignItems:'center',
    justifyContent:"center",
  },
  search: {
    position: 'relative',
    display:"flex",
    alignItems:"center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}))
function OrderManagement() {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [datum, setDatum] = useState({
    orderId: "",
    customOrderId: '',
    driverName: "",
    cancellationReason: ""
  });
  const [open, setOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchText,setSearchText]= useState("");
  
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);

  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };
 
  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/orders/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);
  
  const handleSearch = () => {
    setSearchText(search)
  }
  const handleDateChange = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    setStartDate(year + "-" + month + "-" + day);
  }
  const handleDateChangeTo = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    setEndDate(year + "-" + month + "-" + day);
  }
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#e6e6e6' };
    }
  };
  const { orderId, customOrderId, driverName, cancellationReason } = datum
  const onInputChange = (e) => {
    setDatum({ ...datum, [e.target.name]: e.target.value });
  }
  const handleDelete = () => {

  }
  const columnData = [
    
    {
      headerName: "Order Id",
      field: "orderId",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Custom Order Id",
      field: "customOrderId",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Order Date",
      field: "orderDate",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Amount Delivery",
      field: "amountDelivery",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "GST",
      field: "gst",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Qty",
      field: "qty",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Rate",
      field: "rate",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Payment Type",
      field: "paymentType",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Gateway Charge",
      field: "gateWayCharge",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Buyer Name",
      field: "buyerName",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Buyer Contact No",
      field: "buyerContactNo",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Shipping Address",
      field: "shippingAddress",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Delivery Status",
      field: "deliveryStatus",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Order Status",
      field: "orderStatus",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Driver Status",
      field: "driverStatus",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Delivery Type",
      field: "deliveryType",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Slot",
      field: "slot",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Driver Name",
      field: "driverName",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Cancellation Reason",
      field: "cancellationReason",
      sortable: true,
      headerClass: "column-style",
      minWidth:200
    },
    {
      headerName: "Action",
      field: "action",
      headerClass: "column-style",
      minWidth:200,
      cellRenderer: function (params) {
        return <IconButton onClick={() => setOpen(true)}><IconContext.Provider value={{ className: "edit_icon" }}><FaEdit size={25} /></IconContext.Provider> </IconButton>
      }
    },
  ]
  const gridOptions={
    defaultColDef:{
      flex:"1",
      minWidth:100,
        cellStyle:{
          fontSize:"16px",
        }
    },
    onRowClicked: event => selectedRowData(event)
  }
  const selectedRowData = (event) => {
    setDatum(event?.data)
    setSelectedRowId(event.data?.serialNo)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  return (
    <Paper className={classes.paperStyling} elevation={2}>
      <div className="date-field">
        <div className="form">
          <MuiPickersUtilsProvider className="form-date" utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              format="yyyy-MM-dd"
              fullWidth
              autoOk={true}
              id="Valid-from"
              label="From Date"
              value={startDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider className="form-date" utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              format="yyyy-MM-dd"
              fullWidth
              autoOk={true}
              minDate={new Date(startDate)}
              id="Valid-to"
              label="To Date"
              value={endDate}
              onChange={handleDateChangeTo}
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Button className={classes.btnGo} variant="contained">
          Go
        </Button>
      </div>
      <div className="export-search-field">
        <div className="export-field">
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <SiMicrosoftexcel />
              </span>
            </IconContext.Provider>
            Export to Excel
          </button>
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <FaFilePdf />
              </span>
            </IconContext.Provider>
            Export to PDF
          </button>
        </div>
        <div className={classes.search}>
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67" size={20}  />
          </IconButton>
          <InputBase
            placeholder="Search…"
            value={search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          color="primary"
          shape='rounded' 
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          <span className='edit_title'>
            Edit {selectedRowId}
          </span>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}
            >
              <TextField
                label="Order Id"
                name="orderId"
                value={orderId}
                multiline
                fullWidth
                onChange={(e) => onInputChange(e)}
              />
              <TextField
                placeholder="Custom Order Id"
                name="customOrderId"
                id="outlined-multiline-static"
                value={customOrderId}
                label="Custom Order Id"
                multiline
                fullWidth
                onChange={(e) => onInputChange(e)}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Driver
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Driver"
                  name="driverName"
                  value={driverName}
                  fullWidth
                  onChange={(e) => onInputChange(e)}
                // MenuProps={MenuProps}
                >
                  <MenuItem value={driverName}>{driverName}</MenuItem>
                  <MenuItem value="Item 1">Item 1</MenuItem>
                </Select>
              </FormControl>
              <TextField
                placeholder="Cancellation Reason"
                name="cancellationReason"
                value={cancellationReason}
                label="Cancellation Reason"
                id="outlined-multiline-static"
                multiline
                fullWidth
                onChange={(e) => onInputChange(e)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button className={classes.btn} variant="contained" type="submit">
              Assign
            </Button>
            <Button
              className={classes.btnError}
              variant="contained"
              type="button"
              onClick={handleDelete}
            >
              Cancel Order
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpen(false)}
              className={classes.btnClose}
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  )
}
export default OrderManagement

