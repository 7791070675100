import React, { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { NavLink } from 'react-router-dom';
import { FaUserPlus, FaMotorcycle,FaRegFileAlt } from "react-icons/fa"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { MdDriveEta, MdStarRate, MdLocalShipping, MdAssignment } from "react-icons/md"
import { AiFillClockCircle } from "react-icons/ai"
import { BsFillCartCheckFill } from "react-icons/bs"

import Logo from "../src/images/Arti_Petroleum_logo.png"
const routes = [
  { path: '/', name: "Order Management", icon: <BsFillCartCheckFill size={25} /> },
  { path: '/user-management', name: "User Management", icon: <FaUserPlus size={25} /> },
  {path:'/driver-registration',name:"Driver",icon:<FaRegFileAlt size={25} />},
  { path: '/driver-management', name: "Driver List", icon: <FaMotorcycle size={25} /> },
  { path: '/vehicle-management', name: "Vehicle Management", icon: <MdDriveEta size={25} /> },
  { path: '/rate-management', name: "Rate Management", icon: <MdStarRate size={25} /> },
  { path: '/time-schedule', name: "Time Schedule", icon: <AiFillClockCircle size={25} /> },
  { path: '/shipping-charge', name: "Shipping Charge", icon: <MdLocalShipping size={25} /> },
  { path: '/reports', name: 'Reports', icon: <MdAssignment size={25} /> }
]
function Sidebar() {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.3
      }
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.3
      }

    }
  }
  return (
    <div className="main-container">
      <motion.div
        animate={{
          width: isOpen ? "260px" : "50px",
          transition: {
            duration: 0.5,
            type: "tween",
            damping: 12,
          },
        }}
        className="sidebar"
      >
        <div className="header_section">
          {isOpen && (
            <div className="logo" variants={showAnimation} initial="hidden"
              animate="show"
              exit="hidden">
              <img src={Logo} alt="" />
            </div>
          )}
          <div className="toggle_icon">
            {isOpen ? (
              <IoIosArrowBack onClick={handleToggle} size={25} />
            ) : (
              <IoIosArrowForward onClick={handleToggle} size={25} />
            )}
          </div>
        </div>
        <section className="routes">
          {routes?.map((route, index) => (
            <NavLink
              activeClassName="active"
              to={route.path}
              key={route.name}
              className="link"
            >
              <div className="icon">{route.icon}</div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          ))}
        </section>
      </motion.div>
    </div>
  );
}
export default Sidebar

