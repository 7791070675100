import React from 'react'
import logo from "../../images/Arti_Petroleum_logo.png"
import { NavLink } from 'react-router-dom'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const Signup = () => {
  return (
    <div className="auth-wrapper">
    <div className="auth-content">
        <div className="auth-bg">
            <span className="r"></span>
            <span className="r s"></span>
            <span className="r s"></span>
            <span className="r"></span>
               <span className="r xl"></span>
            <span className="r xl"></span>
            <span className="r l"></span>
            <span className="r l"></span>
        </div>
        <div className="card">
            <div className="card-body text-center">
                <div className="mb-4 logo">
                    <img src={logo} alt="logo"/>
                </div>
                <h3 className="mb-4">Sign up</h3>
                <div className="input-group mb-3">
                    <input type="email" className="form-control" placeholder="Email"/>
                </div>
                <div className="input-group mb-4">
                    <input type="password" className="form-control" placeholder="Password"/>
                </div>
              
                <button className="btn btn-primary shadow-2 mb-4">Sign up</button>
                <p className="mb-0 text-muted">Already have an account? <NavLink to="/login"> Login</NavLink></p>
            </div>
        </div>
    </div>
</div>
  )
}
export default Signup

