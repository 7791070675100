import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState} from 'react';
import {FaFilePdf,FaSearch} from "react-icons/fa";
import {SiMicrosoftexcel} from "react-icons/si";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Button,  Dialog,  DialogActions,  DialogContent,  DialogContentText,  DialogTitle,  FormControl,  IconButton,  InputBase, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {MdAdd} from "react-icons/md";
import { IconContext } from 'react-icons';
import {RiDeleteBin6Fill} from "react-icons/ri";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from 'axios';
import AuthService from "../../lib/Auth";
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnAdd: {
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
    '&:disabled': {
      backgroundColor: alpha("#005B96",0.3),
      color: "#fff",
    },
    color: "#fff",
  },
  paperStyling:{
    width: "100vw",
    height: "calc(100vh -4rem)", 
    marginInline:"2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  dialogTitle :{
    background: "#005b96",
    width: "100%"
 },
 dialogContent :{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  width:"25vw",
  [theme.breakpoints.down('sm')]:{
    width:"60vw",
    gap:"1rem"
  },
},
dialogButtons :{display: "flex",
alignItems: "center",
justifyContent: "center",
gap: "1rem",
paddingBottom: "1rem"
},
btnError: {
  backgroundColor: alpha("#eb3a2d", 1),
  color:"#fff",
  '&:hover' :{
    backgroundColor: alpha("#eb3a2d", 0.90),
  }
},
search: {
  position: 'relative',
  display:"flex",
  alignItems:"center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#cccccc", 0.80),
  '&:hover': {
    backgroundColor: alpha("#b3b3b3", 0.90),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]:{
    marginBottom:"1rem",
    marginTop:"0.8rem"
  },
},
searchIcon: {
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "#FFFFFF"
},
inputRoot: {
  color: 'inherit',
},
inputInput: {
  transition: theme.transitions.create('width'),
  cursor: 'pointer',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '15ch',
  },
},
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TimeSchedule() {
  const classes =useStyles();
  const[open , setOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const[search, setSearch] = useState("");

  const[startingHour, setStartingHour] = useState(new Date());
  const[endingHour, setEndingHour] = useState(new Date());
  const[workingDay, setWorkingDay] = useState("");
  const [selectedRowId , setSelectedRowId] = useState("");
  
  const[searchText ,setSearchText] = useState("");

  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [openSnack,setOpenSnack] = useState(false)
  const [deleteSnack,setDeleteSnack] = useState(false)
  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };



  const columnData = [
    {
      headerName: "Time",
      field: "schedule_time",
      sortable:true,
      headerClass:"column-style",
    },
    {
      headerName: "From Hr",
      field: "starting_hour",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "To Hr",
      field: "ending_hour",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Work Day",
      field: "work_day",
      sortable:true,
      headerClass:"column-style"
    },
    {
      headerName: "Action",
      field: "action",
      headerClass:"column-style",
      cellRenderer: function (params) {
        return <IconButton onClick={() => setDeleteItem(true)}> <IconContext.Provider value={{className:"delete_icon"}}><RiDeleteBin6Fill size={25}/></IconContext.Provider> </IconButton>
        }
    },
  ]
 
  const gridOptions={
    defaultColDef:{
        flex:"1",
        minWidth:100,
          cellStyle:{
            fontSize:"16px",
          }
    },
    onRowClicked: event => selectedRowData(event)
  }
  const selectedRowData = (event) => {
    setSelectedRowId(event?.data.id)
  }
 
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#e6e6e6' };
    }
  };
  const handleSubmit = async(e) => {
    e.preventDefault()

    try{

      var payload = {
        starting_hour: startingHour.getHours(),
        schedule_time: moment(startingHour).format("LT") +" to "+moment(endingHour).format("LT"),
        ending_hour: endingHour.getHours(),
        work_day: workingDay
      }
      await axios.post(process.env.REACT_APP_SERVER_PATH + `/api/v1/time_schedules/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, payload, AuthService.getAuthHeader())
      .then((res)=>{
      }).catch(async() => {
        await AuthService.refreshToken()
      })
      setOpenSnack(true)
      setOpen(false)
      setStartingHour(new Date())
      setEndingHour(new Date())
      setWorkingDay("")
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/time_schedules/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })

    }
    catch (e) {
      console.log("error in Time schedule page", e);
    }
  }

  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/time_schedules/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);
  const handleDelete = async () => {
    await axios.delete(`${process.env.REACT_APP_SERVER_PATH}/api/v1/time_schedules/${selectedRowId}/`,
    AuthService.getAuthHeader()).catch(async () => {
      await AuthService.refreshToken();
    })
    setDeleteSnack(true)
    setDeleteItem(false)
    await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/time_schedules/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
      setData(res.data.results)
    }).catch(async () => {
      await AuthService.refreshToken();
    })
  }

  const handleSearch = ()=>{
    setSearchText(search)

  }


  const handleChangeSearchValue=async(e) => {
    await setSearch(e.target.value)
    if(e.target.value==="") {
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/time_schedules/?limit=${pageSize}&offset=${offset}&search=${e.target.value}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })  
    }

  }

  return (
    <Paper className={classes.paperStyling} elevation={2} >    
      <div className='export-search-field'>
        <div className='export-field'>
            <button type="button"><span><SiMicrosoftexcel /></span> Export to Excel</button>
            <button type="button"><span><FaFilePdf /></span> Export to PDF</button>
             <Tooltip title=" Add items " placement="top-start">
               <Button className={classes.btnAdd} onClick={() => setOpen(true)}>
                  <MdAdd color='#fff' size={25} />
                </Button>
             </Tooltip>
            
        </div>
        <div className={classes.search}>
      
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67"  size={20} />
            </IconButton>

          <InputBase
            placeholder="Search…"
            value={search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => handleChangeSearchValue(e)}
          />
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          shape="rounded"
          color='primary' 
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          <span className='edit_title'>
           Add
          </span>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}             
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                variant='inline'
                label="Time From(hh:mm)*:"
                minutesStep={5}
                fullWidth
                name='starting_hour'
                value={startingHour}
                format="hh:mm a"
                onChange={setStartingHour}
                 />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                variant='inline'
                label="Time To(hh:mm)*:"
                minutesStep={5}
                fullWidth
                name='ending_hour'
                value={endingHour}
                format="hh:mm a"
                onChange={setEndingHour}
                 />
              </MuiPickersUtilsProvider>
              
              <div className='flex' > 
                <TextField
                  label="From Hr*:"
                  name="from-hr"
                  multiline
                  fullWidth
                  value={startingHour.getHours()}
                />
                <TextField
                  label="To Hr*:"
                  name="to-hr"
                  multiline
                  fullWidth
                  value={endingHour.getHours()}
                />
              </div>
              <FormControl fullWidth>
                <InputLabel>
                  Work Day*
                </InputLabel>
                <Select
                  label="Select Day"
                  name="work_day"
                  fullWidth
                  defaultValue={workingDay}
                  onChange={(e)=>setWorkingDay(e.target.value)}
                >
                  <MenuItem value="MON">MON</MenuItem>
                  <MenuItem value="TUE">TUE</MenuItem>
                  <MenuItem value="WED">WED</MenuItem>
                  <MenuItem value="THU">THU</MenuItem>
                  <MenuItem value="FRI">FRI</MenuItem>
                </Select>
              </FormControl>  
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button className={classes.btnAdd} variant="contained" type="submit" disabled={!workingDay}>
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={deleteItem}
        onClose={() => setDeleteItem(false)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete ?</DialogTitle>
        <DialogActions>
          <Button
            className={classes.btnError}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={() => setDeleteItem(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          Added New Item
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={deleteSnack}
        autoHideDuration={2000}
        onClose={() => setDeleteSnack(false)}
      >
        <Alert onClose={() => setDeleteSnack(false)} severity="error">
          Item Deleted
        </Alert>
      </Snackbar>
    </Paper>
  );
}
export default TimeSchedule;

