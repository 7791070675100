import { alpha, Avatar, Button, FormControl, FormControlLabel, makeStyles, Paper, Radio, RadioGroup, Snackbar, TextField, Typography} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react'
import DateFnsUtils from "@date-io/date-fns";
import axios from 'axios';
import AuthService from '../../lib/Auth'
import { useNavigate } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
      paddingBottom:"1rem"
    },
    

  },
  dobInput: {
    paddingBottom: "1.5rem",
    [theme.breakpoints.down('md')]:{
      paddingBottom:"2.5rem",
      width:"100%",
    },
    [theme.breakpoints.down('sm')]:{
      paddingBottom:"1.5rem",
      width:"100%",
    },
  },
  btnSave:{
    backgroundColor: alpha("#005B96", 1),
      '&:hover': {
        backgroundColor: alpha("#005B96", 0.90),
      },
      '&:disabled': {
        backgroundColor: alpha("#005B96",0.3),
        color: "#fff",
      },
      position:"absolute",
    color: "#fff",
    left:"70%",
    top:"88%",
    
    [theme.breakpoints.up('sm')]:{
     top:"75%",
     },
    [theme.breakpoints.up('md')]:{
    top:"80%",
    },
  },
  btnMobile:{
    backgroundColor: "#eb3a2d", 
    color: "white", 
    width:"40%",
    margin:"0 auto"
  },
  avatar_style:{
    height:"200px",
    width:"200px"
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Driver() {
  const navigate=useNavigate();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [universalId, setUniversalId] = useState("");
  const [dob, setDob] = useState(new Date());
  const [licenseId, setLicenseId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [img,setImg]= useState(null);
  const [availability, setAvailability] = useState("");
  const classes = useStyles();
  const [openSnack,setOpenSnack] = useState(false)
  const handleFileUpload = (e) => {
    let reader=new FileReader();
    reader.onloadend=() =>{
      setImg(reader.result)
    }
    reader.readAsDataURL(e.target.files[0])
  }
  const handlePhoneNumber=(e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhoneNum(e.target.value);
    }
  }
  const handleSubmit =async (e) => {
    e.preventDefault()
    try {
      var payload={
        address: address,
        date_of_birth:dob,
        email_id: email,
        first_name:firstName,
        last_name:lastName,
        license_id: licenseId,
        middle_name:middleName,
        password: password,
        phone: phoneNum,       
        universal_id: universalId,       
        user_name: userName,       
        availability: availability,
        img:img
      }
      await axios.post(process.env.REACT_APP_SERVER_PATH + "/api/v1/drivers/?limit=10",
        payload, AuthService.getAuthHeader()).then((res) => {
          setOpenSnack(true) 
          setTimeout(() => {
            navigate("/driver-management");
          }, 1500);
        }).catch(async () => {
          await AuthService.refreshToken();
        })
    } catch (e) {
      console.log("error in add tasting page", e);
    }
      

  }
  const handleDOBChange = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    setDob(year + "-" + month + "-" + day);
  }
  return (
    <Paper className={classes.paperStyling} elevation={2}>
      <div className='driver'>
        <div className='avatar'>
          <Avatar id="uploaded_image" src={img} className={classes.avatar_style}  alt="" />
          <label for="image_uploads">Upload Driver Image</label>
          <input
            type="file"
            style={{ display: "none" }}
            id="image_uploads"
            name="image_uploads"
            accept=".jpg,.jpeg,.png"
            onChange={(e) => handleFileUpload(e)}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className='formFirstSection'>
            <TextField className="text-field"
              label="First Name:"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField className="text-field"
              label="Middle Name:"
              fullWidth
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
            <TextField className="text-field"
              label="Last Name:"
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField className="text-field"
              label="Address:"
              fullWidth
              value={address}
              inputProps={{maxLength:20}}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField className="text-field"
              label="Phone:"
              fullWidth
              value={phoneNum}
              inputProps={{maxLength:10}}
              onChange={(e) => handlePhoneNumber(e)}
            />
            <TextField className="text-field"
              label="Email:"
              type="email"
              fullWidth
              value={email}
              inputProps={{maxLength:40}}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
      
          <div className='formSecondSection'>
            <TextField className="text-field"
              label="Universal Id:"
              fullWidth
              value={universalId}
              onChange={(e) => setUniversalId(e.target.value)}
            />
            <div className={classes.dobInput} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker variant="inline" label="DOB" value={dob} onChange={handleDOBChange} format="yyyy-MM-dd"
                  disableFuture fullWidth

                />
              </MuiPickersUtilsProvider>
            </div>
            <TextField className="text-field"
              label="License Id:"
              value={licenseId}
              fullWidth
              onChange={(e) => setLicenseId(e.target.value)}
            />
            <TextField className="text-field"
              label="User Name:"
              value={userName}
              fullWidth
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField className="text-field"
              label="Password:"
              type="password"
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='availability'>
              <Typography>Availability:</Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  onChange={(e) => setAvailability(e.target.value)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <Button
                className={classes.btnSave}
                type="submit"
                disabled={!firstName||!lastName||!address||!phoneNum||!email||!universalId||!dob||!licenseId||!userName||!password||!availability}>
                Save
              </Button>
            </div>  
          </div>                
        </form>      
      </div>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          Added New Item
        </Alert>
      </Snackbar>
    </Paper>
  );
}
export default Driver;

