import { alpha, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, makeStyles, Paper, Snackbar, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf, FaSearch } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridReact } from 'ag-grid-react';
import axios from "axios";
import AuthService from "../../lib/Auth";
import { IconContext } from 'react-icons/lib';

import Pagination from '@mui/material/Pagination';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  paperStyling: {
    width: "100vw",
    height: "calc(100vh -4rem)",
    marginInline: "2rem",
    marginBottom: "2rem",
    marginTop: "6rem",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    border: "1px solid rgb(145, 142, 142)",
    borderRadius: "10px",
    background: "#fff",
     [theme.breakpoints.down('sm')]:{
      marginLeft:"3.6rem",
      marginRight:"0.6rem",
      marginBottom: "0.5rem",
      marginTop: "5rem",
    },
  },
  btnAdd: {
    backgroundColor: alpha("#005B96", 1),
    '&:hover': {
      backgroundColor: alpha("#005B96", 0.90),
    },
    '&:disabled': {
      backgroundColor: alpha("#005B96",0.3),
      color: "#fff",
    },
    color: "#fff",
  },
  dialogTitle: {
    background: "#005b96",
    width: "100%"

  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    width: "25vw",
    [theme.breakpoints.down('sm')]:{
      width:"60vw",
      gap:"1rem"
    },
  },
  dialogButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    paddingBottom: "1rem"
  },
  search: {
    position: 'relative',
    display:"flex",
    alignItems:"center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("#cccccc", 0.80),
    '&:hover': {
      backgroundColor: alpha("#b3b3b3", 0.90),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]:{
      marginBottom:"1rem",
      marginTop:"0.8rem"
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#FFFFFF"
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
    },
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight:"2rem"
  }
}))
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ShippingCharge() {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [charge, setCharge] = useState("");
  const [minimumKm , setMinimumKm] = useState("");
  const [maximumKm, setMaximumKm] = useState("");
  const [name, setName] = useState("");
  const [updatedDate,setUpdatedDate] = useState(new Date("2022-01-01T18:54"));
  const[searchText ,setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [pageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [openSnack,setOpenSnack] = useState(false)
  const handlePagination = (event, value) => {
    setOffset((value - 1)*pageSize)
  };

  const handleSubmit = async(e) => {
    e.preventDefault()
    try{

      var payload = {
          updated_date:updatedDate,
          charge: charge,
          minimum_km: minimumKm,
          maximum_km: maximumKm,
          name: name
      }

      await axios.post(process.env.REACT_APP_SERVER_PATH + "/api/v1/shipping_charges/", payload, AuthService.getAuthHeader())
      .then((res)=>{
      }).catch(async() => {
        await AuthService.refreshToken()
      })
      setOpenSnack(true)
      setOpenAdd(false)
      setUpdatedDate(new Date("2022-01-01T18:54"))
      setName("")
      setCharge("")
      setMinimumKm("")
      setMaximumKm("")
      await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/shipping_charges/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
        setData(res.data.results)
      }).catch(async () => {
        await AuthService.refreshToken();
      })
    }
    catch (e) {
      console.log("error in shipping charges page", e);
    }

    
  }

  useEffect(() =>{
    let url=`${process.env.REACT_APP_SERVER_PATH}/api/v1/shipping_charges/?limit=${pageSize}&offset=${offset}&search=${searchText}&ordering=-id`
    function fetchData(targetUrl, id, retry){
      axios.get(targetUrl, AuthService.getAuthHeader())
        .then((res) => {
          setData(res?.data.results);
          setNumberOfPages(Math.ceil(res?.data.count/pageSize))
        }).catch(async () => {
          await AuthService.refreshToken();
          if(retry === undefined)fetchData(targetUrl, id, true);
        });
      }
      fetchData(url);
  },[searchText, offset, pageSize ]);


  const columnData = [
    {
      headerName: "Sr No",
      field: "id",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Updated Date",
      field: "updated_date",
      sortable: true,
      headerClass: "column-style",
      cellRenderer: function(params) {
        return (moment(params.value).format("DD/MM/YYYY")+" "+ moment(params.value).format("LT"))
      }
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Charge",
      field: "charge",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Minimum KM",
      field: "minimum_km",
      sortable: true,
      headerClass: "column-style"
    },
    {
      headerName: "Maximum KM",
      field: "maximum_km",
      sortable: true,
      headerClass: "column-style"
    },
  ]
  const rowStyle = { background: '#fff' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#e6e6e6' };
    }
  };
  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth:100,
      cellStyle: {
        fontSize: "16px",
      }
    },
  }

  const handleSearch = ()=>{
    setSearchText(search)

  }

    const handleChangeSearchValue=async(e) => {
      setSearch(e.target.value)
      if(e.target.value==="") {
         await axios.get(process.env.REACT_APP_SERVER_PATH + `/api/v1/shipping_charges/?limit=${pageSize}&offset=${offset}&search=${e.target.value}&ordering=-id`, AuthService.getAuthHeader()).then((res) => {
          setData(res.data.results)
        }).catch(async () => {
          await AuthService.refreshToken();
        })  
      }

    }
  return (
    <Paper className={classes.paperStyling}>
      
      <div className="export-search-field">
        <div className="export-field">
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <SiMicrosoftexcel />
              </span>
            </IconContext.Provider>
            Export to Excel
          </button>
          <button type="button">
            <IconContext.Provider value={{ className: "export_icon" }}>
              <span>
                <FaFilePdf />
              </span>
            </IconContext.Provider>
            Export to PDF
          </button>
          <Tooltip title=" Add items " placement="top-start">
            <Button className={classes.btnAdd} onClick={() => setOpenAdd(true)}>
              <MdAdd color='#fff' size={25} />
            </Button>
          </Tooltip>
         
        </div>
        <div className={classes.search}>
      
          <IconButton onClick={handleSearch}>
            <FaSearch color="#3F4D67"  size={20} />
            </IconButton>

          <InputBase
            placeholder="Search…"
            value={search}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => handleChangeSearchValue(e)}
          />
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          gridOptions={gridOptions}
          columnDefs={columnData}
          debounceVerticalScrollbar={true}
          rowData={data}
        ></AgGridReact>
        <Pagination className={classes.paginationStyle} count={numberOfPages} 
          showFirstButton 
          showLastButton
          color="primary"
          shape="rounded"
          onChange={handlePagination}
        />
      </div>
      <Dialog
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          <span className='edit_title'>
            Add
          </span>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
          <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogContent}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  variant='inline'
                  label="Updated Date*:"
                  format="yyyy-MM-dd hh:mm a"
                  fullWidth
                  value={updatedDate}
                  onChange={setUpdatedDate}
                />
              </MuiPickersUtilsProvider>             
              <TextField
                label="Name*:"
                name="name"
                multiline
                fullWidth
                value={name}
              onChange={(e)=>setName(e.target.value)}
              />
              <TextField
                label="Charge*:"
                name="charge"
                id="outlined-multiline-static"
                multiline
                fullWidth
              value={charge}
              onChange={(e)=>setCharge(e.target.value)}
              />
              <TextField
                name="minimumkm:"
                label="Minimum KM*:"
                id="outlined-multiline-static"
                multiline
                fullWidth
                value={minimumKm}
              onChange={(e)=>setMinimumKm(e.target.value)}
              />
              <TextField
                name="maximumkm:"
                label="Maximum KM*:"
                id="outlined-multiline-static"
                multiline
                fullWidth
                value={maximumKm}
              onChange={(e)=>setMaximumKm(e.target.value)}
              />

            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogButtons}>
            <Button
              className={classes.btnAdd}
              variant="contained"
              type="submit"
              disabled={!name||!charge||!minimumKm||!maximumKm}
            >
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => setOpenAdd(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={openSnack}
        autoHideDuration={2000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          Added New Item
        </Alert>
      </Snackbar>
    </Paper>
  )
}
export default ShippingCharge

