import axios from 'axios';

const USER_API_BASE_URL = process.env.REACT_APP_SERVER_PATH;

class AuthService {

    login(credentials) {
        return axios.post(USER_API_BASE_URL + "/api/v1/token/", credentials);
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return { headers: { Authorization: 'Bearer ' + this.getUserInfo().access } };
    }

    async refreshToken() {
        let isValid = true;
        let refresh = this.getUserInfo() && this.getUserInfo().refresh ? this.getUserInfo().refresh : undefined;
        let access = this.getUserInfo() && this.getUserInfo().access ? this.getUserInfo().access : undefined;
        if (refresh === undefined) {
            if (window.location.pathname !== '/login') window.location.href = '/login';
            return isValid;
        }
        if (access === undefined) {
            if (window.location.pathname !== '/login') window.location.href = '/login';
            return isValid;
        }
        await axios.post(USER_API_BASE_URL + "/api/v1/token/verify/", {
            "token": access
        }).then(res => {

        }).catch(async (err) => {
            // console.log(err);
            await axios.post(USER_API_BASE_URL + "/api/v1/token/refresh/", {
                "refresh": refresh
            }).then(res => {
                if (res.data && res.data.access) {
                    let data = {
                        "access": res.data.access,
                        "refresh": refresh,
                    }
                    localStorage.setItem("userInfo", JSON.stringify(data));
                    isValid = false;
                } else {
                    console.log("Data: ", res.data)
                    isValid = true;
                    window.location.href = '/login';
                }
            }).catch((exception) => {
                this.logOut()
            })
        })
        
        return isValid;
    }

    async verifyToken() {
        await axios.post(USER_API_BASE_URL + "/api/v1/token/verify/", {
            "token": this.getUserInfo().refresh
        }).then(res => {
            console.log(res.data)
        });
        // return {headers: {Authorization: 'Bearer ' + this.getUserInfo().refresh }};
    }

    logOut() {
        axios.post(USER_API_BASE_URL + '/api/v1/logout/', {}, this.getAuthHeader());
        localStorage.removeItem("userInfo");
        window.location.href = "/login";
    }
}
export default new AuthService();

